import React from "react";
import Layout from "src/components/Layout";
import SEOStatic from "src/components/SEOStatic";
import { PageContextProvider } from "src/containers/PageContext";

import Image from "asset/bitmaps/404.jpg";

import "style/pages/not-found.scss";

export default function NotFound({ pageContext }) {
	return (
		<PageContextProvider context={{ ...pageContext, is404: true }}>
			<Layout className="layout--not-found  not-found">
				<SEOStatic title="Page Not Found" description="" />
				<img src={Image} alt="" />
			</Layout>
		</PageContextProvider>
	);
}
